.footer {
  margin-top: 15px;
  color: white;
  a {
    color: white;
    font-weight: bold; } }
.country-selection {
  a.country {
    display: block;
    border: solid 2px white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    color: white;
    background-color: #ffffff24;
    .flag {
      width: 50px;
      display: inline-block; }
    .name {
      display: inline-block;
      width: 100px; }
    &:hover {
      background-color: #ffffff47;
      cursor: pointer; } } }
.person-selection {
  .title {
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    display: block; }
  a.person {
    display: block;
    border: solid 2px white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    color: white;
    background-color: #ffffff24;
    .icon {
      width: 50px;
      display: inline-block; }
    .name {
      display: inline-block;
      width: 200px; }
    &:hover {
      background-color: #ffffff47;
      cursor: pointer; } }
  .country {
    display: block;
    margin-bottom: 15px;
    .name {
      color: white; } } }
